<template>
  <div class="pdf-modal-wrapper">
    <a-modal
      width="80%"
      class="pdf-modal"
      centered
      @scroll="onScroll"
      bodyStyle="height:90%"
      :class="[currentLocale]"
      :visible="visible"
      :title="title"
      :cancelButtonProps="{ style: { display: 'none' } }"
      :okText="buttonText"
      @ok="handleOk(true)"
      @cancel="handleCancle(false)"
    >
      <div id="pdf-wrapper">
        <div class="d-block">
          <template>
            <a-skeleton :loading="!isshowpdf" />
            <a-skeleton :loading="!isshowpdf" />
            <a-skeleton :loading="!isshowpdf" />
          </template>
          <pdf
            v-for="i in numPages"
            :key="i"
            :v-if="isshowpdf"
            :src="src"
            :page="i"
            style="display: inline-block; width: 100%"
          />
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import Mixin from '@/mixin/Mixin'
import pdf from 'vue-pdf'
import Locale from '@/helper/locale.js'

export default {
  name: 'PdfModal',
  mixins: [Mixin],
  components: {
    pdf,
  },
  props: {
    title: {
      type: String,
      require: true,
      default: '',
    },
    visible: {
      type: Boolean,
      require: true,
      default: false,
    },
    pathPdf: {
      type: String,
      require: true,
      default: null,
    },
    conditionName: {
      type: String,
      require: true,
      default: '',
    },
  },
  data() {
    return {
      buttonText: '',
      currentPage: 0,
      isshowpdf: false,
      numPages: null,
      path: this.pathPdf,
      pageCount: 0,
      src: null,
      termAndCondition: [],
      type: '',
      currentLocale: Locale.getLocaleShort(),
    }
  },
  watch: {
    visible(newValue) {
      this.visible = newValue
    },
  },
  mounted() {
    this.buttonText = this.lbl['register-accept-pdf-button']
    var loadingTask = pdf.createLoadingTask(this.pathPdf)
    this.src = loadingTask
    this.src.promise.then(pdf => {
      this.isshowpdf = true
      this.numPages = pdf.numPages
    })
  },
  methods: {
    onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      if (scrollTop + clientHeight >= scrollHeight) {
        console.log('bottom')
        this.isagree = false
      }
    },
    handleTermAndCondition() {
      return new Promise(resolve => {
        if (this.pathPdf.includes('term')) {
          this.type = 'term'
          this.termAndCondition.push('term')
        } else if (this.pathPdf.includes('privacy')) {
          this.type = 'privacy'
          this.termAndCondition.push('privacy')
        }
        resolve()
      })
    },
    handleOk(e) {
      console.log('handleOk :: ', e)
      this.handleTermAndCondition().then(() => {
        if (this.conditionName == 't&c') {
          this.$emit('accept', {
            conditionName: this.conditionName,
            accept: e,
            type: this.type,
          })
        } else {
          this.$emit('accept', { conditionName: this.conditionName, accept: e })
        }
      })
    },
    handleCancle(e) {
      console.log('handleCancle :: ', e)
      this.$emit('close', e)
      if (this.conditionName == 't&c') {
        this.$emit('accept', { isMarketing: true, accept: e })
      } else {
        this.$emit('accept', { isMarketing: false, accept: e })
      }
    },
    error(err) {
      console.log(err)
    },
  },
}
</script>

<style lang="scss" scoped></style>
