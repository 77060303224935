<template>
  <div class="container-login">
    <div class="box-login text-center">
      <!-- language -->
      <div>
        <div class="language">
          <a-select
            id="register_select_language_option_box"
            :default-value="currentLocale"
            class="select-language"
          >
            <a-select-option
              id="register_select_language_en_option"
              value="en"
              @click="switchLang('en')"
            >
              <img
                src="@/assets/images/en.svg"
                class="mr-2 iconlang"
                alt=""
              />ENG
            </a-select-option>
            <a-select-option
              id="register_select_language_th_option"
              value="th"
              @click="switchLang('th')"
            >
              <img
                src="@/assets/images/th.svg"
                class="mr-2 iconlang"
                alt=""
              />TH
            </a-select-option>
            <a-select-option
              id="register_select_language_th_option"
              value="ph"
              @click="switchLang('ph')"
            >
              <img
                src="@/assets/images/ph.svg"
                class="mr-2 iconlang"
                alt=""
              />PH
            </a-select-option>
          </a-select>
        </div>
        <div class="mb-2">
          <img
            src="@/assets/images/newlogocolored.svg"
            alt=""
            class="logo-image"
          />
        </div>
        <div :class="{ 'mb-3': isPH }">
          <label class="text-welcome">
            {{ lbl['select-page-welcome-to-crm-pLus-in-three-minutes'] }}
          </label>
        </div>
        <div class="mb-3">
          <div v-if="!isPH" class="promode-text">
            {{ lbl['promode-register-for-free-package-text'] }}
          </div>
        </div>
        <div>
          <div v-if="isApiError" class="message-error w-100 mb-2">
            <a-alert type="error">
              <span slot="description" class="d-flex">
                <img src="@/assets/images/lock.svg" alt="" />
                <span class="lbl-error">{{ errorapi }}</span>
              </span>
            </a-alert>
          </div>
          <a-form-item class="text-left">
            <div class="text-userpw">{{ lbl['new-login-username'] }}</div>
            <a-input
              id="register_username_input"
              v-model="username"
              :placeholder="lbl['register-username-example']"
              autocomplete="off"
              @copy.prevent
              @paste.prevent
              @click.right.prevent
              @keypress="onlyEn"
              @change="checkUsername"
            />
            <div v-if="isErrorUsername" class="text-alert">
              <span
                >{{ lbl['register-username-password-min-length-message'] }}
              </span>
            </div>
            <div v-if="isErrorConditionUsername" class="text-alert">
              <span>{{ lbl['invalid-format'] }} </span>
            </div>
            <div class="text-userpw mt-2">{{ lbl['new-login-password'] }}</div>
            <a-input-password
              id="register_password_input"
              v-model="password"
              :placeholder="lbl['register-password-inputpassword-placeholder']"
              type="password"
              autocomplete="off"
              :maxLength="20"
              @copy.prevent
              @paste.prevent
              @click.right.prevent
              @keypress="onlyPassword"
              @change="checkPassword"
            />
            <div v-if="isErrorPassword" class="text-alert">
              <span>{{ lbl['register-password-error-message'] }} </span>
            </div>
            <div class="text-userpw mt-2">
              {{ lbl['register-confirm-password-placeholder'] }}
            </div>
            <a-input-password
              id="register_confirm_password_input"
              v-model="confirmpassword"
              autocomplete="off"
              :maxLength="20"
              @copy.prevent
              @paste.prevent
              @click.right.prevent
              @keypress="onlyPassword"
              :placeholder="lbl['register-password-inputpassword-placeholder']"
              @change="checkConfirmPassword"
            />
            <div v-if="isErrorConfirmPassword" class="text-alert">
              <span
                >{{ lbl['register-confirm-password-not-match-message'] }}
              </span>
            </div>
            <div class="text-userpw mt-2">
              {{ lbl['user-permissions-email'] }}
            </div>
            <a-input
              id="register_email_input"
              :class="[isErrorEmail ? 'input-error' : '']"
              v-model="email"
              :placeholder="lbl['forgot-password-email-placeholder-example']"
              allow-clear
              @change="checkEmail"
            />
            <div v-if="isErrorEmail" class="text-alert">
              <span class="alert-email"
                >{{ lbl['register-email-invalid-format-message'] }}
              </span>
            </div>
            <div class="text-userpw mt-2">
              {{ lbl['register-mobile-input'] }}
            </div>
            <a-input
              id="register_mobile_input"
              :class="[isErrorMobileNumber ? 'input-error' : '']"
              v-model="mobileNumber"
              @input="checkMobileInput($event)"
              :maxLength="maxlength"
              :minLength="maxlength"
              :placeholder="lbl['register-mobile-placeholder']"
              allow-clear
              @change="checkMobile"
            />
            <div v-if="isErrorMobileNumber" class="text-alert">
              <span class="alert-mobile"
                >{{ lbl['register-mobile-invalid-format-message'] }}
              </span>
            </div>
            <div class="term-and-conditions-wrapper mb-4">
              <a-checkbox
                id="register_term_and_conditions_check_box"
                :checked="term_conditions"
                :class="[isErrortermconditions ? 'text-error' : '']"
                @change="onChangeTermAndConditions"
              >
                {{ lbl['register-term-and-condition-messege-prefix'] }}
                <span id="register_term_and_conditions_link" class="btn-term">
                  {{ lbl['register-term-and-condition-messege-term'] }}
                </span>
                {{ lbl['register-term-and-condition-messege-linking'] }}
                <span id="register_term_and_conditions_link" class="btn-term">
                  {{ lbl['register-term-and-condition-messege-privacy'] }}
                </span>
              </a-checkbox>
            </div>
          </a-form-item>
        </div>
        <a-button
          id="register_submit_button"
          class="btn_login"
          block
          @click="submitRegister()"
        >
          {{ lbl['register-button'] }}
        </a-button>
        <hr />
        <div v-if="!isPH" class="promode-text">
          {{ lbl['promode-register-for-free-package-text'] }}
        </div>
        <span>
          {{ lbl['select-page-question'] }}
          <a id="register_login_link" class="btn-term" @click="handleLogin()">
            {{ lbl['new-login-button'] }}
          </a>
        </span>
      </div>
    </div>
    <PdfModal
      v-if="isShowPdfModal"
      :title="pdfModalTitle"
      centered
      :visible="isShowPdfModal"
      :path-pdf="pdfModalPath"
      :condition-name="conditionName"
      @accept="acceptPDF($event)"
      @close="closePDF($event)"
    />
  </div>
</template>

<script>
import Mixin from '@/mixin/Mixin'
import Locale from '@/helper/locale.js'
import BzbsMerchant from '@/core/Merchant/service/BzbsMerchant'
import Account from '@/helper/AccountHelper.js'
import BzbsProfile from '@/core/Account/service/BzbsProfile'
import PdfModal from '@/components/Modal/Component/PdfModal'
import Vue from 'vue'
import Cache from '@/helper/Cache.js'

export default {
  name: 'RegisterVue',
  components: { PdfModal },
  mixins: [Mixin],
  data() {
    return {
      currentLocale: 'en',
      username: '',
      password: '',
      confirmpassword: '',
      email: '',
      mobileNumber: '',
      termAndConditionType: '',
      marketingMessage: '',
      termPath: '',
      privacyPath: '',
      marketingMessagePath: '',
      pdfModalTitle: '',
      pdfModalPath: '',
      conditionName: '',
      currenttimestamp: new Date().getTime(),
      isShowPdfModal: false,
      errorapi: '',
      isApiError: false,
      visibleMarketing: false,
      term_conditions: false,
      // age_condition: false,
      // marketing: false,
      isErrorConditionUsername: false,
      isUsernameNotnull: false,
      isErrortermconditions: false,
      // isErrorAgecondition: false,
      // isErrorMarketingMessage: false,
      isErrorUsername: false,
      isPasswordNotnull: false,
      isErrorPassword: false,
      isConfirmPasswordNotnull: false,
      isErrorConfirmPassword: false,
      isEmailNotnull: false,
      isErrorEmail: false,
      isMobileNumberNotnull: false,
      isErrorMobileNumber: false,
      maxlength: 10,
      isPH: false,
    }
  },
  created() {
    this.init()
    Account.bzbsAnalyticTracking(
      'register_page',
      'register',
      'view_register',
      'on view',
    )
    this.googleTagManager(
      'register_page',
      'register',
      'view_register',
      null,
      'on view',
    )
  },
  methods: {
    init() {
      this.handleFooter(true)
      if (Vue.bzbsConfig.businessRule.webFor.PH) {
        this.isPH = true
      } else {
        this.isPH = false
      }
      this.maxlength = Vue.bzbsConfig.businessRule.max.phoneLength
      this.currentLocale = Locale.getLocaleShort()
      this.getTermAndCondition()
      this.getMarketingMessage()
    },
    getTermAndCondition() {
      Promise.all([this.getPrivacy(), this.getTerm()])
    },
    getMarketingMessage() {
      return new Promise(resolve => {
        var language
        if (this.currentLocale == 'ph') {
          language = 'en'
        } else {
          language = this.currentLocale
        }
        var prefix
        var suffix
        language == 'en' ? (prefix = 'EN') : (prefix = 'TH')
        language == 'en' ? (suffix = 'US') : (suffix = 'TH')
        var FileName = 'marketing' + prefix + '_' + suffix + '.pdf'
        var blobMarketingUrlPdf =
          Vue.bzbsConfig.bzbsBlobUrl +
          '/config/crmplus/conditionbzbs/' +
          FileName +
          '?t=' +
          this.currenttimestamp
        this.marketingMessagePath = blobMarketingUrlPdf
        resolve(this.marketingMessagePath)
      })
    },
    getTerm() {
      return new Promise(resolve => {
        var language
        if (this.currentLocale == 'ph') {
          language = 'en'
        } else {
          language = this.currentLocale
        }
        var prefix
        var suffix
        language == 'en' ? (prefix = 'EN') : (prefix = 'TH')
        language == 'en' ? (suffix = 'US') : (suffix = 'TH')
        var FileName = 'term' + prefix + '_' + suffix + '.pdf'
        var blobMarketingUrlPdf =
          Vue.bzbsConfig.bzbsBlobUrl +
          '/config/crmplus/conditionbzbs/' +
          FileName +
          '?t=' +
          this.currenttimestamp
        this.termPath = blobMarketingUrlPdf
        resolve(this.termPath)
      })
    },
    getPrivacy() {
      return new Promise(resolve => {
        var language
        if (this.currentLocale == 'ph') {
          language = 'en'
        } else {
          language = this.currentLocale
        }
        var prefix
        var suffix
        language == 'en' ? (prefix = 'EN') : (prefix = 'TH')
        language == 'en' ? (suffix = 'US') : (suffix = 'TH')
        var FileName = 'privacy' + prefix + '_' + suffix + '.pdf'
        var blobMarketingUrlPdf =
          Vue.bzbsConfig.bzbsBlobUrl +
          '/config/crmplus/conditionbzbs/' +
          FileName +
          '?t=' +
          this.currenttimestamp
        this.privacyPath = blobMarketingUrlPdf
        resolve(this.privacyPath)
      })
    },
    onlyEn($event) {
      console.log('$event : ', $event, $event.target.value)
      if ($event.keyCode != 32) {
        const regex_en = /^[A-Z a-z]\d*$/
        const regex_no = /^[0-9]\d*$/
        const regex_sb = /^[!@#$%^&*?_~]\d*$/
        var match_en = $event.key.match(regex_en)
        var match_no = $event.key.match(regex_no)
        var match_sb = $event.key.match(regex_sb)

        if (match_en == null && match_no == null && match_sb == null) {
          $event.preventDefault()
        }
      } else {
        $event.preventDefault()
      }
    },
    onlyPassword($event) {
      console.log('$event : ', $event, $event.target.value)
      if ($event.keyCode != 32) {
        const regex_en = /^[A-Z a-z]\d*$/
        const regex_no = /^[0-9]\d*$/
        const regex_sb = /^[!@#$%^&*?_~]\d*$/
        var match_en = $event.key.match(regex_en)
        var match_no = $event.key.match(regex_no)
        var match_sb = $event.key.match(regex_sb)

        if (match_en == null && match_no == null && match_sb == null) {
          $event.preventDefault()
        }
      } else {
        $event.preventDefault()
      }
    },
    checkUsername(e) {
      var value
      if (e.target) {
        value = e.target.value
      } else {
        value = e
      }
      var checklength = value.length
      if (checklength < 6) {
        this.isUsernameNotnull = true
        this.isErrorUsername = true
      } else {
        this.isUsernameNotnull = false
        this.isErrorUsername = false

        var strength = 0

        strength += /[A-Z]+/.test(value) ? 1 : 0
        strength += /[a-z]+/.test(value) ? 1 : 0
        strength += /[!@#$%^&*?_~]+/.test(value) ? 1 : 0
        strength += /[0-9]+/.test(value) ? 1 : 0
        if (strength >= 1) {
          this.isErrorConditionUsername = false
        } else {
          this.isErrorConditionUsername = true
        }
      }
    },
    checkPassword(e) {
      var value
      if (e.target) {
        value = e.target.value
      } else {
        value = e
      }
      var checklength = value.length
      if (checklength < 6) {
        this.isPasswordNotnull = true
        this.isErrorPassword = true
      } else {
        this.isPasswordNotnull = false
        this.isErrorPassword = false
      }
    },
    checkConfirmPassword(e) {
      var value
      if (e.target) {
        value = e.target.value
      } else {
        value = e
      }
      if (this.password != null || this.password != '') {
        if (this.password == value) {
          this.isConfirmPasswordNotnull = true
          this.isErrorConfirmPassword = false
        } else {
          this.isConfirmPasswordNotnull = false
          this.isErrorConfirmPassword = true
        }
      } else {
        this.isConfirmPasswordNotnull = false
        this.isErrorConfirmPassword = true
      }
    },
    checkEmail(e) {
      var value
      if (e.target) {
        value = e.target.value
      } else {
        value = e
      }
      if (this.validEmail(value)) {
        this.isEmailNotnull = true
        this.isErrorEmail = false
      } else {
        this.isEmailNotnull = false
        this.isErrorEmail = true
      }
    },
    validEmail: function (email) {
      const recheck =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return recheck.test(String(email).toLowerCase())
    },
    checkMobile(e) {
      var value
      if (e.target) {
        value = e.target.value
      } else {
        value = e
      }
      if (this.validMobileNumber(value)) {
        this.isMobileNumberNotnull = true
        this.isErrorMobileNumber = false
      } else {
        this.isMobileNumberNotnull = false
        this.isErrorMobileNumber = true
      }
    },
    checkMobileInput(e) {
      this.mobileNumber = e.target.value.replace(/[^0-9]/g, '')
    },
    validMobileNumber: function (mobile) {
      var recheck = /^[0-9]*$/g
      if (this.isPH) {
        if (mobile.length == 11 && recheck.test(String(mobile).toLowerCase())) {
          return true
        } else return false
      } else {
        recheck = /^(0[689]{1})+([0-9]{8})+$/g
        return recheck.test(String(mobile).toLowerCase())
      }
    },
    onChangeTermAndConditions(e) {
      Account.bzbsAnalyticTracking(
        'register_page',
        'register',
        'click_term_condition',
        'on click',
      )
      Account.bzbsAnalyticTracking(
        'register_page',
        'register',
        'click_pdpa',
        'on click',
      )
      this.googleTagManager(
        'register_page',
        'register',
        'click_pdpa',
        null,
        'on click',
      )
      console.log(`checked = ${e.target.checked}`)
      this.pdfModalTitle = this.lbl['register-topic-term-and-condition']
      this.conditionName = 't&c'
      this.pdfModalPath = this.termPath
      this.isShowPdfModal = true
      this.isErrortermconditions = false
    },
    // onChangeAgeCondition(e) {
    //   Account.bzbsAnalyticTracking(
    //     'register_page',
    //     'register',
    //     'click_confirm_age',
    //     'on click',
    //   )
    //   console.log(`checked = ${e.target.checked}`)
    //   this.age_condition = e.target.checked
    //   this.isErrorAgecondition = false
    // },
    // onChangeMarketing() {
    //   Account.bzbsAnalyticTracking(
    //     'register_page',
    //     'register',
    //     'click_marketing_opt',
    //     'on click',
    //   )
    //   this.pdfModalTitle = this.lbl['register-topic-marketing-messege']
    //   this.conditionName = 'marketing'
    //   this.pdfModalPath = this.marketingMessagePath
    //   this.isShowPdfModal = true
    //   this.isErrorMarketingMessage = false
    // },
    acceptPDF(e) {
      this.termAndConditionType = e.type
      this.isShowPdfModal = false
      if (e.conditionName == 't&c') {
        if (e.type == 'privacy') {
          this.term_conditions = e.accept
        } else {
          this.handleLoading(true)
          setTimeout(() => {
            //show privacy
            this.conditionName = 't&c'
            this.pdfModalPath = this.privacyPath
            this.isShowPdfModal = true
            this.isErrorMarketingMessage = false
            this.handleLoading(false)
          }, 500)
        }
        this.isErrortermconditions = false
      } else {
        this.marketing = e.accept
        this.isErrorMarketingMessage = false
      }
    },
    closePDF(e) {
      this.isShowPdfModal = false
      if (e.conditionName == 't&c') {
        // this.marketing = e.accep
        this.term_conditions = e.accept
      } else {
        this.marketing = e.accept
      }
    },
    submitRegister() {
      this.handleLoading(true)
      if (this.checkForm()) {
        Account.bzbsAnalyticTracking(
          'register_page',
          'register',
          'click_register',
          'on click',
        )
        this.googleTagManager(
          'register_page',
          'register',
          'click_register',
          null,
          'on click',
        )
        return new Promise(resolve => {
          BzbsMerchant.apiRegister(
            this.username,
            this.password,
            this.confirmpassword,
            this.email,
            this.mobileNumber,
          )
            .then(response => {
              Account.apiBzbsLoginRegister(
                this.username,
                this.password,
                this.mobileNumber,
              )
                .then(() => {
                  let configSelfOnBoard = false
                  try {
                    let parse = JSON.parse(
                      localStorage.getItem('bzbsCRMConfig'),
                    )
                    if (parse) {
                      configSelfOnBoard = parse.buzzebeesConfigSelfOnBoard
                        ? parse.buzzebeesConfigSelfOnBoard
                        : false
                    } else {
                      configSelfOnBoard =
                        Vue.bzbsConfig.businessRule.open.package
                    }
                  } catch (e) {
                    configSelfOnBoard = Vue.bzbsConfig.businessRule.open.package
                  }

                  if (configSelfOnBoard) {
                    Account.apiAccountInformation()
                      .then(res => {
                        this.handleLoading(false)
                        if (res.data.RequirePackage) {
                          if (res.data.RequirePackage) {
                            if (res.data.IsExtendExpired) {
                              this.$router.push({
                                name: 'Package',
                                query: { returnPath: 'AccountSetting2' },
                              })
                            } else {
                              this.$router.push({ name: 'AccountSetting2' })
                            }
                          } else {
                            this.$router.push({
                              name: 'Package',
                              query: { returnPath: 'AccountSetting2' },
                            })
                          }
                        } else {
                          this.$router.push({ name: 'AccountSetting2' })
                        }
                      })
                      .catch(error => {
                        this.handleLoading(false)
                        resolve(error)
                      })
                  } else {
                    this.handleLoading(false)
                    this.$router.push({ name: 'AccountSetting' })
                  }
                })
                .catch(error => {
                  this.handleLoading(false)
                  resolve(error)
                })
              console.log('apiRegister : ', response)
              resolve(response.data)
            })
            .catch(error => {
              this.handleLoading(false)
              console.log(error)
              this.isApiError = true
              this.errorapi = error.response.data.error.message
              resolve(error)
            })
        })
      } else {
        this.handleLoading(false)
      }
    },
    checkForm() {
      if (!this.term_conditions) {
        this.isErrortermconditions = true
      }
      // if (!this.age_condition) {
      //   this.isErrorAgecondition = true
      // }
      // if (!this.marketing) {
      //   this.isErrorMarketingMessage = true
      // }
      if (!this.username) {
        this.isErrorUsername = true
      } else {
        if (this.checkUsername(this.username)) {
          this.isErrorUsername = true
        }
      }
      if (!this.password) {
        this.isErrorPassword = true
      } else {
        if (this.checkPassword(this.password)) {
          this.isErrorPassword = true
        }
      }

      if (!this.confirmpassword) {
        this.isErrorConfirmPassword = true
      } else {
        if (this.checkConfirmPassword(this.confirmpassword)) {
          this.isErrorConfirmPassword = true
        }
      }

      if (!this.email) {
        this.isErrorEmail = true
      } else {
        if (this.checkEmail(this.email)) {
          this.isErrorEmail = true
        }
      }
      if (!this.mobileNumber) {
        this.isErrorMobileNumber = true
      } else {
        if (this.checkMobile(this.mobileNumber)) {
          this.isErrorMobileNumber = true
        }
      }

      if (
        !this.isErrorUsername &&
        !this.isErrorPassword &&
        !this.isErrorConfirmPassword &&
        !this.isErrorEmail &&
        !this.isErrorMobileNumber &&
        !this.isErrortermconditions
        //  &&
        // !this.isErrorAgecondition &&
        // !this.isErrorMarketingMessage
      )
        return true
    },
    async switchLang(code) {
      this.handleLoading(true)
      Account.bzbsAnalyticTracking(
        'register_page',
        'register',
        'click_change_language',
        'on click',
      )
      this.googleTagManager(
        'register_page',
        'register',
        'click_change_language',
        null,
        'on click',
      )
      this.updateLanguage(code)
      await this.setLang(code)
      this.$router.go()
    },
    updateLanguage(code) {
      var locale
      switch (code) {
        case 'th':
          locale = 1054
          break
        case 'en':
          locale = 1033

          break
        case 'ph':
          locale = 13321

          break
        default:
          locale = 1033
      }
      return new Promise(resolve => {
        BzbsProfile.updateLanguage(locale)
          .then(res => {
            var CRMUserCookie = Cache.get('CRMUser')
            CRMUserCookie.Token = res.data.data
            Cache.set('CRMUser', CRMUserCookie)
            resolve(res.data)
          })
          .catch(err => {
            console.log(err)
            resolve(err)
          })
      })
    },
    handleLogin() {
      Account.bzbsAnalyticTracking(
        'register_page',
        'register',
        'click_login',
        'on click',
      )
      this.googleTagManager(
        'register_page',
        'register',
        'click_login',
        null,
        'on click',
      )
      this.$router.push('/Account/Login')
    },
  },
}
</script>

<style lang="scss">
@import '@/style/main.scss';
.logo-image {
  width: fit-content;
  height: fit-content;
}
.box-login {
  position: absolute;
  top: 46%;
  left: 50%;
  border-radius: 12px;
  background-color: $color-white;
  padding: 30px;
  transform: translate(-50%, -50%);
  box-shadow: 0 16px 48px 0 $color-black-2b;
  width: 445px;
  max-height: 90%;
  overflow: auto;

  .title-login {
    font-size: 48px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: left;
    color: $black;
  }

  .ant-input {
    height: 40px;
    font-size: 15px;
  }

  .anticon svg {
    color: $color-black-32;
  }

  .btn_login {
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-bottom: 12px;
    padding: 10px;
    background-color: $primary;
    border: initial;
    font-size: 16px;
    font-weight: bold;
    color: $color-white;
  }
  .text-userpw {
    line-height: 24px;
    font-size: 14px;
    font-weight: bold;
    color: $color-grey-42;
  }
  .btn-term {
    color: $color-blue-f3;
    text-decoration: underline;
  }
  .promode-text {
    color: $red;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 4px;
  }
  .language {
    text-align: right;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1.25px;
    .ant-select {
      min-width: 91px;
    }
  }
  .lbl-error {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: 0.25px;
    text-align: left;
    color: $color-red-52;
    padding-left: 5px;
  }
  .form-input {
    padding: 0px 30px 0px 30px;
  }
  .text-alert {
    font-size: 12px;
    color: $color-red-52;
    line-height: 18px;
    .alert-email {
      color: $color-red-52;
    }
  }
  .iconlang {
    width: 15px;
    height: 15px;
    border-radius: 4px;
  }
  .text-welcome {
    color: $text-dark;
    font-weight: 500;
  }
  .ant-form-item {
    margin-bottom: 0px;
  }
  .text-error {
    color: $color-red-52;
  }
  .input-error {
    input {
      border-color: $color-red-52;
    }
  }
}
hr {
  margin-top: 1.3rem;
}
.TAndCBody {
  height: 70vh;
  padding: 20px;
  overflow-y: auto;
}
.ant-modal-body {
  text-align: left !important;
  padding: 0px !important;
  color: $body !important;
}
.ant-modal {
  top: 5% !important;
}
.ant-modal-root .ant-modal-close .ant-modal-close-x .anticon {
  color: $color-black-73 !important;
}
@media only screen and (max-device-width: 600px) and (orientation: portrait) {
  .box-login {
    width: 80%;
  }
}

@media only screen and (max-device-width: 600px) and (orientation: landscape) {
  .box-login {
    width: 80%;
  }
}
</style>
