import Vue from 'vue'
import BzbsApi from '@/core/Http/BzbsApi.js'

/**
 * @method apiRegister
 *  */

 export default {
    config: function () {
      return Vue.bzbsConfig
    },
    apiRegister: function(username, password, confirmpassword, email, mobile) {
        var params = {
            app_id: this.config().client_id,
            username: username,
            password: password,
            confirmpassword: confirmpassword,
            email: email,
            contact_number: mobile,
            custom_info: "{\"service\":\"crmplus\"}"
        };

        var url = this.config().bzbsUrl + "/auth/register";
        return BzbsApi.connectBzbsPost(url, null, params);
    },
    

}